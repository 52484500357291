import { Component, Prop } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import { CommonBaseMixin } from './mixins/base'

@Component
export default class DemoBannerDialog extends CommonBaseMixin {
  @Prop({ type: Boolean, default: false }) showFeedback!: boolean

  get dialog (): QDialog {
    return this.$refs.demoBannerDialog as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.hide()
  }

  async confirm () {
    this.$emit('ok')
    this.hide()
  }
}
